import { TOKEN } from '../common.config'
import Promise from 'promise-polyfill'
import 'whatwg-fetch'
import $ from '../tc/tc-query-selector'
import 'babel-polyfill';

if (!window.Promise) {
  window.Promise = Promise;
}

// const has = Object.prototype.hasOwnProperty;
// has.call(object, key)

const _ANIMATION_TIMEOUT = 500,
      _BASE_URL = location.origin,
      _CLASS_NAME = {
        loading: 'is-loading',
        sucess: 'was-sucessfull',
        error: 'has-error',
        pasteError: 'error-message'
      },
      _TCFORM_INPUT = {
        prefix: 'prefix_',
        suffix: 'suffix_',
        compare: 'confirm_'
      },
      _SELECTOR = {
        prefixInput: `[name^=${_TCFORM_INPUT.prefix}]`,
        suffixInput: `[name^=${_TCFORM_INPUT.suffix}]`,
        compare: `[name^=${_TCFORM_INPUT.compare}]`,
        required: '[required]',
        submit: '[type=submit]'
      },
      _ATTRIBUTE_ATTACH = 'data-tcform-attach',
      _ATTRIBUTE_GROUP = 'data-tcform-group',
      _ATTRIBUTE_METHOD = 'data-tcform-method',
      _ATTRIBUTE_URL = 'data-tcform-url',
      _ATTRIBUTE_REDIRECT = 'data-tcform-redirect',
      _HEADERS = new Headers ({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': TOKEN
      });


export default class TcForm {
  constructor (selector, options = {}, headers = _HEADERS) {
    this.selector = selector
    this.options = options
    this.headers = headers
    this.form = $(this.selector)[0];
    // this.textareas = Array.from($('textarea'), this.form)
    this.inputs = $('input', this.form)
    this.selects = $('select', this.form)
    this.requiredFields = $(_SELECTOR.required, this.form)
    this.compareFields = $(_SELECTOR.compare, this.form)
    this.prefixFields = $(_SELECTOR.prefixInput, this.form)
    this.suffixFields = $(_SELECTOR.suffixInput, this.form)
    this.method = this.form.getAttribute(_ATTRIBUTE_METHOD) || false
    this.url = this.form.getAttribute(_ATTRIBUTE_URL) || false
    this.redirect = this.form.getAttribute(_ATTRIBUTE_REDIRECT) || false

    this.init()
  }




  getFieldValue (field) {
    return field.type === 'select-one' ? field[field.selectedIndex].value : field.value
  }




  collectDataToJSON () {
    let data = {},
        modifierValue = '',
        inputToModify = ''

    let filterData = (inputType) => {
      if (inputType.length) {
        inputType.forEach((input) => {
          if (!input.name.startsWith(_TCFORM_INPUT.prefix) && !input.name.startsWith(_TCFORM_INPUT.suffix)) {
            if (input.type === 'submit' || (input.name.startsWith(_TCFORM_INPUT.compare) && !input.hasAttribute(_ATTRIBUTE_ATTACH))) {
              return
            }

            if (!input.hasAttribute(_ATTRIBUTE_GROUP)) {
              if (input.type === 'radio') {
                if (input.checked) {
                  data[input.name] = this.getFieldValue(input)
                  return
                }
              } else {
                data[input.name] = this.getFieldValue(input)
              }
            } else {
              data[input.getAttribute(_ATTRIBUTE_GROUP)] = data[input.getAttribute(_ATTRIBUTE_GROUP)] || {}
              data[input.getAttribute(_ATTRIBUTE_GROUP)][input.name] = this.getFieldValue(input)
            }
          }
        })
      }

      if($('textarea', this.form)[0]) {
        data[$('textarea', this.form)[0].name] = $('textarea', this.form)[0].value
      }
    }

    // check if there are any fields to PREFIX or SUFFIX its value
    let modifyCollectedData = (modifier) => {
      let modifiers = '',
          modifierValue = ''

      if (modifier === _TCFORM_INPUT.prefix) {
        modifiers = this.prefixFields
      }

      if (modifier === _TCFORM_INPUT.suffix) {
        modifiers = this.suffixFields
      }

      if (modifiers.length) {
        modifiers.forEach((field) => {
          let inputName = field.name.replace(modifier, '')

          modifierValue = this.getFieldValue(field)
          inputToModify = $(`[name="${inputName}"]`)[0]

          if (modifier === _TCFORM_INPUT.prefix) {
            data[inputName] = modifierValue.concat(inputToModify.value)
          }

          if (modifier === _TCFORM_INPUT.suffix) {
            data[inputName] = inputToModify.value.concat(modifierValue)
          }
        })
      }
    }

    filterData(this.inputs)
    filterData(this.selects)
    modifyCollectedData(_TCFORM_INPUT.prefix)
    modifyCollectedData(_TCFORM_INPUT.suffix)

    return JSON.stringify(data)
  }




  validate () {
    let valid = true

    let addErrors = (valid, fields) => {
      if(!valid) {
        setTimeout(() => {
          fields.forEach((field) => {
            field.classList.add(_CLASS_NAME.error)
          })

          if(this.options.stateClass.error) {
            $(this.options.stateClass.error)[0].classList.add(_CLASS_NAME.error)
          }
        }, _ANIMATION_TIMEOUT)

        this.animationStop()
      }
    }

    let compareInputs = (input) => {
      let rePattern = new RegExp(input.pattern),
          compareInput = $(`input[name=${input.name.replace(_TCFORM_INPUT.compare, '')}]`, this.form)[0]

      valid = (input.value === compareInput.value && (rePattern).test(compareInput.value))

      addErrors(valid, [input, compareInput])
    }

    let requiredInputs = (input) => {
      addErrors(input.value.length, [input])
    }


    if (!this.requiredFields && !this.compareFields) {
      return valid
    }

    if(this.requiredFields) {
      this.requiredFields.forEach(requiredInputs.bind(this))
    }

    if(this.compareFields) {
      this.compareFields.forEach(compareInputs.bind(this))
    }

    return valid
  }




  animationStart () {
    if(this.options.hasOwnProperty('stateClass')) {
      if(this.options.stateClass.hasOwnProperty('loading')) {
        $(this.options.stateClass.loading)[0].classList.add(_CLASS_NAME.loading)
      }
    }
  }




  animationStop () {
    setTimeout(() => {
      if(this.options.hasOwnProperty('stateClass')) {
        if(this.options.stateClass.hasOwnProperty('loading')) {
          $(this.options.stateClass.loading)[0].classList.remove(_CLASS_NAME.loading)
        }
      }
    }, _ANIMATION_TIMEOUT)
  }




  httpRequest (e) {
    let json;

    e.preventDefault()

    if(this.options.hasOwnProperty('stateClass')) {
      if(this.options.stateClass.hasOwnProperty('error')) {
        if($(this.options.stateClass.error)[0].classList.contains(_CLASS_NAME.error)) {
          return
        }
      }
    }

    // Clear password errors as well, another hack
    for (var i = 0; i < $('.password-error').length; i++) {
        $('.password-error')[i].innerHTML = '';
    }
    for (var i = 0; i < $('.old-password-error').length; i++) {
        $('.old-password-error')[i].innerHTML = '';
    }

    // And another hack for the phone number field
    var phoneError = document.getElementsByClassName('c-form-phone__error-message');
    if (phoneError.length > 0) {
      phoneError[0].classList.remove('has-error');
    }

    this.animationStart()
    json = this.collectDataToJSON()

    if(this.validate()) {
      fetch(`${_BASE_URL}${this.url}`, {
        headers: this.headers,
        credentials: 'same-origin',
        method: this.method.toUpperCase(),
        body: json
      }).then((response) => {
          this.animationStop()

          if (response.ok) {
            if(this.options.hasOwnProperty('stateClass')) {
              if(this.options.stateClass.hasOwnProperty('sucess')) {
                $(this.options.stateClass.sucess)[0].classList.add(_CLASS_NAME.sucess)
              }
            }

            if(this.redirect) {
              sessionStorage.clear();
              location = `${this.redirect}`
              return
            }
          }

          if (this.options.stateClass.pasteError) {
            return response.json()
          }

        }).then((myJSON) => {
        // Hack for the password field, should be solved properly
          if ("password" in myJSON || "old_password" in myJSON) {
              if ("password" in myJSON) {
                  $(this.options.stateClass.pasteError)[0].innerHTML = myJSON.password[0];
              } else {
                  $(this.options.stateClass.pasteErrorTwo)[0].innerHTML = myJSON.old_password[0];
              }
          } else {
              // Hack for the phone number field, should be solved properly
              var phoneError = document.getElementsByClassName('c-form-phone__error-message');
              if (phoneError.length > 0) {
                phoneError[0].classList.add('has-error');
              }

              $(this.options.stateClass.pasteError)[0].innerHTML = myJSON.non_field_errors[0]
          }
        }).catch(response => {
          // if (this.options.stateClass.error) {
          //   $(this.options.stateClass.error)[0].classList.add(_CLASS_NAME.error)
          // }
        })
    }
  }




  clearInputsErrors (e) {
    let origin,
        compare,
        inputName = e.target.name,
        hasPrefix = inputName.startsWith(_TCFORM_INPUT.compare)

    if (!e.target.classList.contains(_CLASS_NAME.error)) { return }

    if (hasPrefix) {
      origin = $(`input[name=${inputName.replace(_TCFORM_INPUT.compare, '')}]`, this.form)[0]
      compare = $(`input[name=${inputName}]`, this.form)[0]
    } else {
      origin = $(`input[name=${inputName}]`, this.form)[0]
      compare = $(`input[name=${_TCFORM_INPUT.compare}${inputName}]`, this.form)[0]
    }

    origin.classList.remove(_CLASS_NAME.error)
    compare.classList.remove(_CLASS_NAME.error)
    compare.value = ''

    setTimeout(() => {
      if(this.options.stateClass.error) {
        $(this.options.stateClass.error)[0].classList.remove(_CLASS_NAME.error)
      }
    }, 0)
  }




  init () {
    if(!this.form.length || !this.method || !this.url) {
      return
    }

    switch(this.method.toUpperCase()) {
      case 'POST':
      case 'PATCH':
      case 'PUT':
        this.form.addEventListener('submit', this.httpRequest.bind(this), false)
        break
      default:
        break
    }

    if (this.inputs.length) {
      this.inputs.forEach((input) => {
        input.addEventListener('click', this.clearInputsErrors.bind(this))
      })
    }
  }
}
