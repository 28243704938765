import { API_URLS } from '../common.config'
import { downloadDataWithCache } from '../tc/tc-get-data'
import $ from '../tc/tc-query-selector'
import moment from 'moment'

export function getBlockWidget(element, hideDashboardContainerAddressConfirmationDeadline){
  return new Vue({
  el: element,
  delimiters: ['${', '}'],
  data: {
    blockAccount: false,
    hasAddressDeadline: false,
    daysLeft: 5,
  },

  methods: {

  },

  beforeMount: function () {
    var vm = this

    downloadDataWithCache(API_URLS.employee.details).then((data) => {
      let employeeDetails = data;
      vm.hasAddressDeadline = employeeDetails.has_address_abroad && !employeeDetails.address_form_filled;

      if ((vm.daysLeft < 1) && (vm.hasAddressDeadline)) {
        vm.blockAccount = true;
        $(".dashboardContainer")[0].classList.add("is-hidden-all");
      }
    })

    downloadDataWithCache(API_URLS.notifications.address_conformation_deadlines).then((data) => {
      if (data.results[0] !== undefined){
        let deadlineDate = data.results[0].addressconformationDeadline;
        let momentDeadline = moment(deadlineDate);

        let momenttoday = moment();
        let duration = moment.duration(momentDeadline.diff(momenttoday))
        let durationDays = Math.ceil(duration.as('days'))

        vm.daysLeft = durationDays;

        if ((vm.daysLeft < 1) && (vm.hasAddressDeadline)) {
          vm.blockAccount = true;
          if(hideDashboardContainerAddressConfirmationDeadline) {
            $(".dashboardContainer")[0].classList.add("is-hidden-all");
          }
        }
      }
    })
  }
})
}

