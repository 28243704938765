const SHIFT_KIND_WORK_VALUE = "Work";
const SHIFT_KIND_IS_NOT_WORK_VALUE = "Is not work";
const SHIFT_KIND_RESERVE_VALUE = "Reserve";
const SHIFT_KIND_RESERVE_BY_PHONE_VALUE = "Reserve (by Phone)";

const SHIFT_KIND_WORK_CLASS = "shift-kind-work";
const SHIFT_KIND_IS_NOT_WORK_CLASS = "shift-kind-is-not-work";
const SHIFT_KIND_RESERVE_CLASS = "shift-kind-reserve";
const SHIFT_KIND_RESERVE_BY_PHONE_CLASS = "shift-kind-reserve-by-phone";
const DEFAULT_CLASS = "shift-kind-default";

export const getShiftKindClass = (shiftKind) => {
  if (shiftKind === SHIFT_KIND_WORK_VALUE) {
    return SHIFT_KIND_WORK_CLASS;
  } else if (shiftKind === SHIFT_KIND_IS_NOT_WORK_VALUE) {
    return SHIFT_KIND_IS_NOT_WORK_CLASS;
  } else if (shiftKind === SHIFT_KIND_RESERVE_VALUE) {
    return SHIFT_KIND_RESERVE_CLASS;
  } else if (shiftKind === SHIFT_KIND_RESERVE_BY_PHONE_VALUE) {
    return SHIFT_KIND_RESERVE_BY_PHONE_CLASS;
  }
  return DEFAULT_CLASS;
};
