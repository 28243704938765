import $ from "./tc-query-selector";

const _CLASS_BODY_OPENED = "has-modal-opened",
  _CLASS_MODAL_OPEN = "js-modal-open",
  _CLASS_MODAL_OPENED = "is-opened",
  _CLASS_MODAL_ANIMATE = "is-animating",
  _ANIMATION_TIMEOUT = 300;

export default class TcModal {
  constructor(selector, forceInteraction = false) {
    this.selector = selector;
    this.modal = $(`${selector}`)[0];
    this.closers = $(".js-modal-close", this.modal);
    this.forceInteraction = forceInteraction;
    this.init();
  }

  open(e) {
    e.stopPropagation();
    $(`.${this.dataset.modal}`)[0].classList.add(_CLASS_MODAL_ANIMATE);
    setTimeout(() => {
      $(`.${this.dataset.modal}`)[0].classList.add(_CLASS_MODAL_OPENED);
    }, _ANIMATION_TIMEOUT);
  }

  close() {
    this.modal.classList.remove(_CLASS_MODAL_ANIMATE);
    setTimeout(() => {
      this.modal.classList.remove(_CLASS_MODAL_OPENED);
    }, _ANIMATION_TIMEOUT);
  }

  elementIsChild(element) {
    return element.target.matches(`${this.selector} *`);
  }

  ignoreClick(element) {
    element.stopPropagation();
    element.preventDefault();
  }

  handleClicksOutsideModal(e) {
    if (this.elementIsChild(e)) {
      return;
    }
    this.ignoreClick(e);
  }

  setForcedInteractionHandler() {
    this.callback = (e) => this.handleClicksOutsideModal(e);

    document.addEventListener("click", this.callback, true);
  }

  setDefaultClickHandler() {
    document.body.addEventListener("click", () => {
      this.close();
    });
  }

  selectorExists() {
    return this.modal !== undefined;
  }

  unlock() {
    document.removeEventListener("click", this.callback, true);
  }

  init() {
    if (!this.selectorExists()) {
      return;
    }

    this.modal.addEventListener("click", (e) => {
      e.stopPropagation();
    });

    if (this.forceInteraction) {
      this.setForcedInteractionHandler();
    } else {
      this.setDefaultClickHandler();
    }

    this.closers.forEach((closeModal) => {
      closeModal.addEventListener("click", this.close.bind(this));
    });

    if ($(".js-modal-open").length) {
      $(".js-modal-open").forEach((opener) => {
        opener.addEventListener("click", this.open);
      });
    }
  }
}
