export default function getCookie (name) {
  let cookieValue = null

  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split('; '),
        cookiesNum = cookies.length,
        nameLength = name.length

    for (var i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim()

      if (cookie.substring(0, nameLength + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}
