import { API_URLS } from '../common.config'
import { downloadDataWithCache } from '../tc/tc-get-data'

function closest(el, selector) {
    var matchesFn;

    // find vendor prefix
    ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
        if (typeof document.body[fn] == 'function') {
            matchesFn = fn;
            return true;
        }
        return false;
    })

    var parent;

    // traverse parents
    while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
            return parent;
        }
        el = parent;
    }

    return null;
}

export const vueAccordion = new Vue({
  el: '#vue-accordion',
  delimiters: ['${', '}'],
  data: {
    questions: [],
    url: '',
    isCollapsed: true,
    user: {},
  },
  watch: {
    url: function () {
      downloadDataWithCache(this.url).then((data) => {
        vm.questions = [...data.results]
      })
    }
  },
  methods: {
    toggleAccordion: function (e) {
      closest(e.target, 'li').classList.toggle('is-collapsed')
    },
    updateUrl: function () {
      return this.$el.dataset.url
    }
  },

  beforeMount() {
    let vm = this
    downloadDataWithCache(API_URLS.employee.details).then((data) => {
      if (data.date_of_birth) {
        data.date_of_birth = data.date_of_birth.split('-').reverse().join('-')
      }
      vm.user = data
    })
    if (!this.updateUrl()) {
      return
    }
    downloadDataWithCache(this.updateUrl()).then((data) => {
      vm.questions = [...data.results]
    })
  }
})
