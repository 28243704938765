const files = document.querySelectorAll('input[type="file"]')

function handleChange () {
  let btn = this.parentNode
  
  if (this.value) {
    btn.classList.add('is-active')
  } else {
    btn.classList.remove('is-active')
  }
}

function attachEvent (file) {
 file.addEventListener('change', function () {
  handleChange.call(file)
 })
}


files.forEach(attachEvent)
