import moment from "moment";
import Moment from "moment-timezone";

const getCurrentLang = () => {
  return window.location.pathname.split("/")[1];
};

// Set a default timezone, but then try to get it from the browser
moment.tz.setDefault("Europe/Amsterdam");

// Now set Locale
const currentLang = getCurrentLang();
moment.updateLocale(currentLang, {
  // Inherit language settings but not date formatting settings
  longDateFormat: {
    LTS: "HH:mm:ss",
    LT: "HH:mm",
    L: "DD-MM-YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
});
moment.locale(currentLang);
const localeData = moment.localeData();
const months = localeData.months();
const weekdaysShort = localeData.weekdaysShort();

export const getTime = (timestamp, showSeconds = false) => {
  let mom = ensureMoment(timestamp);
  return showSeconds ? mom.format("LTS") : mom.format("LT");
};

export const getDate = (timestamp) => {
  let config = {
    timeZone: "Europe/Amsterdam",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  return new Date(timestamp * 1000).toLocaleString("nl-NL", config);
};

export const ensureMoment = (date) => {
  if (date instanceof moment) {
    return date;
  }
  if (date instanceof Date) {
    return moment(date);
  }
  if (typeof date === "number") {
    if (date < 2000000000) {
      // this leaves a reasonable gap (the month Januari 1970)
      date *= 1000;
    }
    return moment(date);
  }
  if (typeof date === "string") {
    // let's be prickly about incorrect formats
    let mdate = moment(date);
    if (mdate.isValid()) {
      return mdate;
    }
  }
  throw new Error("Unknown date format '" + typeof date + "' '" + date + "'");
};

export const getDateSortable = (date) => {
  // YYYY-MM-DD
  return ensureMoment(date).format("YYYY-MM-DD");
};

export const reverseDateString = (dateString) => {
  return dateString.split("-").reverse().join("-");
};

export const monthName = (monthNrBase0) => {
  return months[monthNrBase0];
};

export const formatTimestamp = (
  date,
  format = "DD-MM-YYYY HH:mm",
  allowUnknown = false,
) => {
  if (allowUnknown && date === "unknown") {
    return date;
  }
  return ensureMoment(date).format(format);
};

// Date Object based, perhaps replace with moment solutions in stead
export const normalizeDate = (secs) => {
  return new Date(secs * 1000);
};

export const getWeekNumber = (ts) => {
  // Was afraid to touch this one and replace it with moment

  let d = new Date(+ts);
  d.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  d.setDate(d.getDate() + 3 - ((d.getDay() + 6) % 7));
  // January 4 is always in week 1.
  let week1 = new Date(d.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week 1.
  let weekNo =
    1 +
    Math.round(
      ((d.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7,
    );
  return weekNo;
};
