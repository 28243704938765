import $ from "./tc-query-selector";

export default function TcMenu() {
  let menu = $(".js-menu-open")[0],
    sideMenu = $(".js-menu-side")[0],
    body = document.body,
    appContent = $("#app main")[0],
    languageSelects = $(".js-language-select");

  function stopPropagation(e) {
    e.stopPropagation();
  }

  function openSideMenu(e) {
    e.stopPropagation();
    body.classList.add("has-menu-opened");
    appContent.classList.add("has-events-disabled");
  }

  function closeOpenElements(e) {
    body.classList.remove("has-menu-opened");
    appContent.classList.remove("has-events-disabled");
    languageSelects.forEach((element) => {
      element.classList.contains("is-closed")
        ? false
        : element.classList.add("is-closed");
    });
  }

  if (menu) {
    menu.addEventListener("click", openSideMenu);
  }
  sideMenu.addEventListener("click", stopPropagation);
}
