import $ from "./tc/tc-query-selector";
import TcDropdown from "./tc/tc-dropdown";
import TcMenu from "./tc/tc-menu";
import "./tc/tc-file-handler";
import "babel-polyfill";
import moment from "moment";
import MomentTZ from "moment-timezone";
import { checkNotifications } from "./tc/tc-check-notifications";

const languageDropdown = new TcDropdown(".js-language-select");

moment.locale("nl");

if (typeof NodeList.prototype.forEach !== "function") {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

TcMenu();

checkNotifications();
