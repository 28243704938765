import {TOKEN} from '../common.config';
import Promise from 'promise-polyfill';
import 'whatwg-fetch';

if (!window.Promise) {
  window.Promise = Promise;
}

function fetchUrl(url) {
  return fetch(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRFToken': TOKEN
    },
    method: 'GET',
    redirect: 'follow',
    credentials: 'same-origin',
  }).then((response) => {
    return response.json().then((json) => {
      return json;
    }).catch((error) => {
      console.warn(error);
    })
  }).catch((error) => {
    console.warn(error);
  });
}

const downloadDataCache = [];

function downloadDataWithCache (url) {
  if (downloadDataCache.some(el => el.url === url)) {
    const cacheRow = downloadDataCache.find(el => el.url === url);
    return cacheRow.promise;
  }
  const fetchPromise = fetchUrl(url);

  downloadDataCache.push({url: url, promise: fetchPromise});
  return fetchPromise;
}

function downloadData (url) {
  return fetchUrl(url);
}


function getData (key, type='session') {
  switch (type.toLowerCase()) {
    case 'session':
      return JSON.parse(sessionStorage.getItem(key))
      break
    case 'local':
      return JSON.parse(localStorage.getItem(key))
      break
    default:
    break
  }
}


function setData (key, data, type='session') {
  let string = JSON.stringify(data)
  switch (type.toLowerCase()) {
    case 'session':
      sessionStorage.setItem(key, string)
      break
    case 'local':
      localStorage.setItem(key, string)
      break
    default:
    break
  }
}

function downloadEvent(event) {
  const element = event.currentTarget
  const parent = event.target.parentNode

  const url = element.getAttribute('href')

  event.preventDefault()

  fetch(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRFToken': TOKEN
    },
    method: 'GET',
    redirect: 'follow',
    credentials: 'same-origin',
  }).then((response) => {
    if (response.status != 200)
    {
      event.stopPropagation()

      let errorMsg = parent.querySelector('.not-found-error') || document.getElementsByClassName('modal-not-found-error')[0]

      if (errorMsg !== null) {
        errorMsg.classList.remove('is-hidden-all')
      }
      return null
    }
    return response.blob()
  }).then(function (responseBlob) {
    if (!responseBlob) {
      return null
    }

    window.location = url

  })
}

export { downloadDataWithCache, downloadData, getData, setData, downloadEvent }
