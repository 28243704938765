export const closest = function(el, selector) {
    var matchesFn;

    // find vendor prefix
    ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
        if (typeof document.body[fn] == 'function') {
            matchesFn = fn;
            return true;
        }
        return false;
    })

    var parent;

    // traverse parents
    while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
            return parent;
        }
        el = parent;
    }

    return null;
}

export const currencyFormatter = function(value, region) {
    // Default or 'nl'
    var format = "%u %n"
    var unit = '€'

    if (region == 'cee') {
        format = "%n %u"
        unit = 'zł'
    }

    // Format the number with commas
    const formattedNumber = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // Replace %u with the currency unit and %n with the formatted number
    return format.replace("%u", unit).replace("%n", formattedNumber);
  }
