import {API_URLS} from '../common.config'
import {downloadDataWithCache} from '../tc/tc-get-data'
import {currencyFormatter} from '../components/utils.js'

export const vueLastPayslip = new Vue({
  el: '#vue-last-payslip',
  delimiters: ['${', '}'],
  data: {
    payslips: [{
      net_salary: 0,
      end_datetime: 0,
      leave_balance_money: 0,
      leave_balance_hours: 0
    }, {
      net_salary: 0,
      end_datetime: 0,
      leave_balance_money: 0,
      leave_balance_hours: 0,
      week: 0,
      month_name: ''
    }],
    paydate: -1,
    daysLeft: -1,
  },
  computed: {
    lastPayslip() {
      return this.payslips[0]

    },
    previousPayslip() {
      return this.payslips[1]
    }
  },
  methods: {
    currency(value, region = 'nl') {
      return currencyFormatter(value, region);
    },
    normalizeDate(ms) {
      return (new Date(ms * 1000)).toJSON().split('T')[0].split('-').reverse().join('-');
    }
  },
  created() {
    let vm = this
    downloadDataWithCache(API_URLS.salary.payslips).then((data) => {
      vm.payslips = [...data.results];

      if (vm.payslips[0]) {
        vm.leave_balance_money = vm.payslips[0].leave_balance_money;
        vm.leave_balance_hours = vm.payslips[0].leave_balance_hours;
      }
    })
    downloadDataWithCache(API_URLS.salary.paydate).then((data) => {
      let today = new Date(Date.now()).setHours(0, 0, 0, 0),
        paydate = new Date(data.paydate * 1000).setHours(0, 0, 0, 0)

      vm.paydate = new Date(paydate).toJSON().split('T')[0].split('-').reverse().join('-');
      vm.daysLeft = Math.round((paydate - today) / (1000 * 60 * 60 * 24))
    })
  }
})
