import $ from './tc-query-selector'

if (typeof NodeList.prototype.forEach !== "function") {
  NodeList.prototype.forEach = Array.prototype.forEach
}

export default class TcDropdown {
  constructor (selector) {
    this._dropdown = $(selector)[0]
    this._listElements = this._dropdown.querySelectorAll('li')
    this.attachEvents()
  }

  attachEvents () {
    // Remove and re-add the dropdown to prevent event listeners from stacking
    var old_dropdown = this._dropdown
    var new_dropdown = old_dropdown.cloneNode(true)
    new_dropdown.addEventListener('click', this.openMenu)
    new_dropdown.querySelectorAll('li').forEach((element) => {
      element.addEventListener('click', this.handleClick)
    })
    old_dropdown.parentNode.replaceChild(new_dropdown, old_dropdown)
  }

  openMenu (e) {
    e.stopPropagation()

    if (window.innerWidth < 1025) { return }
    this.classList.toggle('is-closed')
  }

  handleClick () {
    if (window.innerWidth < 1025) { return }

    let activeClassName = 'is-active',
        valueAttr = 'data-value'

    if(!this.classList.contains(activeClassName)) {
      location = this.getAttribute(valueAttr)
    }
  }
}
