import {API_URLS} from '../common.config'
import {downloadData, downloadEvent} from '../tc/tc-get-data'
import '../components/accordion'
import $ from '../tc/tc-query-selector'
import {getDate} from '../components/dateTimeConverter'
import {closest, currencyFormatter} from '../components/utils.js'

export const vueSalary = new Vue({
  el: '#vue-salary-list',
  delimiters: ['${', '}'],
  data: {
    payslipsAnnual: [],
    payslips: [{
      net_salary: 0,
      end_datetime: 0
    }, {
      net_salary: 0,
      end_datetime: 0,
      payroll_period: 0
    }],
    paydate: -1,
    daysLeft: -1,
    nextPage: null,
    nextPageAnnual: null,
    allYears: [],
    lastPayslip: null,
    previousPayslip: null,

  },
  methods: {
    toggleAccordion(e) {
      closest(e.target, 'li').classList.toggle('is-collapsed')
    },
    download(event) {
      return downloadEvent(event)
    },
    currency(value, region = 'nl') {
      return currencyFormatter(value, region)
    },
    normalizeDate(timestamp) {
      return getDate(timestamp)
    },
    loadMore() {
      var vm = this
      downloadData(this.nextPage).then((data) => {
        vm.payslips = vm.payslips.concat(data.results)
        vm.nextPage = data.next
      })
    },
    changeYear(event) {
      var vm = this,
        newYear = event.target.value;

      downloadData(API_URLS.salary.payslips + '?year=' + newYear).then((data) => {
        vm.payslips = [...data.results]
        vm.nextPage = data.next
      })
    },
    loadMoreAnnual() {
      var vm = this
      downloadData(this.nextPageAnnual).then((data) => {
        vm.payslipsAnnual = vm.payslipsAnnual.concat(data.results)
        vm.nextPageAnnual = data.next
      })
    },
    openModal(month, week, salary, payslip_url, payslip_url_nl) {
      $('.js-modal-salary')[0].classList.add('is-animating')

      $('#modal-month')[0].innerHTML = month
      $('#modal-week')[0].innerHTML = week
      $('#modal-salary')[0].innerHTML = salary
      $('#modal-link')[0].href = payslip_url + '/?download=true&filename=payslip.pdf'
      if ($('#modal-link-dutch').length > 0) {
        // Dutch link isn't shown when Dutch language is selected
        $('#modal-link-dutch')[0].href = payslip_url_nl + '/?download=true&filename=payslip_nl.pdf'
      }

      setTimeout(() => {
        $('.js-modal-salary')[0].classList.add('is-opened')
      }, 300)
    }
  },
  beforeMount() {
    var vm = this
    downloadData(API_URLS.salary.annual_payslips).then((data) => {
      vm.payslipsAnnual = [...data.results]
      vm.nextPageAnnual = data.next
    })

    downloadData(API_URLS.salary.payslip_years).then((data) => {
      vm.allYears = [...data]
      if (vm.allYears.length > 0) {
        downloadData(API_URLS.salary.payslips + '?year=' + vm.allYears[0]).then((data) => {
          vm.payslips = [...data.results]
          vm.nextPage = data.next
          if (vm.payslips.length > 0) {
            vm.lastPayslip = vm.payslips[0]

          }
          if (vm.payslips.length > 1) {
            vm.previousPayslip = vm.payslips[1]
          }
        })
      }
    })
    downloadData(API_URLS.salary.paydate).then((data) => {
      let today = new Date(Date.now()).setHours(0, 0, 0, 0),
        paydate = data.paydate

      vm.paydate = getDate(data.paydate)
      vm.daysLeft = Math.round((paydate * 1000 - today) / (1000 * 60 * 60 * 24))
    })
  }
})
