import { API_URLS } from "../common.config";
import { downloadData, downloadDataWithCache } from "../tc/tc-get-data";
import $ from "../tc/tc-query-selector";

const DEFAULT_DOCUMENT_TITLE = "OTTO Workforce";

const messageCountIsZero = (count) => {
  return count == 0 || count == "0";
};

const generateDocumentTitle = (count) => {
  return messageCountIsZero(count)
    ? DEFAULT_DOCUMENT_TITLE
    : `(${count}) ${DEFAULT_DOCUMENT_TITLE}`;
};

const setDocumentTitle = (count) => {
  document.title = generateDocumentTitle(count);
};

export const setMessageCount = (setTitle = false) => {
  const newMessagesElement = document.querySelector('[data-new-messages]');
  
  if (!newMessagesElement) return;
  
  downloadData(API_URLS.helpdesk.count_new_messages)
    .then(response => {
      const { counter } = response.result;
      newMessagesElement.setAttribute('data-new-messages', counter);
  
      if (setTitle) {
        setDocumentTitle(counter);
      }
    })
    .catch(error => console.error('Failed to download data:', error));
};

export const vueMenu = new Vue({
  el: "#vue-block-menu",
  methods: {},

  beforeMount: function () {
    setMessageCount();
  },
});
